section.not-found {
    display: grid;
    place-items: center;
    min-height: 75vh;

    div.not-found-container {
        display: flex;
        align-items: center;
        gap: 32px;

        div.not-found-text {
            h2 {
                margin-bottom: 16px;
            }

            h3 {
                margin-bottom: 12px;
            }
        }
    }   
}