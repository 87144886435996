section#faq {
    color: #424242;

    div.content {
        display: flex;
        gap: 60px;
        padding: 100px 40px;

        div.faq-navigation {
            flex: 1;
        }
    }

    div.question-container {
        margin: 0 auto;
        max-width: 740px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        div.question {
            font-size: 14px;
            width: 100%;

            div.topbar {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                border-radius: 4px;
                padding: 8px;
                background-color: #ececec;
                border: 1px solid #424242;
                cursor: pointer;
            }

            div.answer-container {
                background-color: #ececec;
                overflow: hidden;
                transition: all 0.3s ease-in-out;
                max-height: 0;
                padding: 0;
            }

            div.answer-container.open {
                max-height: 500px;
                padding: 16px 12px;
            }
        }
    }
}