$cl-header-background: #27555B;
$cl-header-button: #947B57;

header {
    z-index: 100;
    position: sticky;
    top: 0;
    
    height: 84px;
    width: calc(100% - 108px);
    padding: 0 60px 0 48px;

    // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: var(--cl-white);
    transition: 0.4s ease-in-out;

    button.menu-btn {
        padding: 12px;
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
        color: white;
        cursor: pointer;
    }

    div.dummy-header {
        position: sticky;
        top: 0;
        width: 100%;
        height: 84px;
        background-color: $cl-header-background;
    }

    &.header {
        background-color: rgba(18, 34, 35, 0.01);
        backdrop-filter: blur(5px);
    }

    &.header-scrolled {
        background-color: $cl-header-background;
    }

    a {
        display: grid;
        place-items: center;
        color: var(--cl-white);
    }

    div.flex-end,
    div.flex-start {
        width: 100%;
    }

    div.flex-end {
        gap: 30px;
    }

    div:first-child div {
        font-size: 16px;
    }

    a.logo {
        padding: 10px 15px;

        img {
            height: 50px;
        }
    }

    a.res-btn {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 10px 20px;
        border-radius: 27px;
        // background-color: rgba(18, 34, 35, 0.5);
        background-color: $cl-header-button;
        backdrop-filter: blur(10px);
        transition: 0.25s ease-in-out;

        &:hover {
            // background-color: #122223;
            background-color: #a58c73;
        }
    }

    /* LINK GROUPS */

    div.group {
        position: relative;
        padding-right: 30px;

        div.label {
            display: grid;
            place-items: center;
            height: 84px;
            cursor: pointer;
        }

        &:hover ul {
            display: flex;
        }

        ul {
            display: none;
            position: absolute;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            top: 100%;
            left: 0;
            min-width: 160px;
            padding: 30px;
            background-color: $cl-header-background;
        
            li a {
                font-size: 16px;
                text-transform: capitalize;

                &:hover {
                    color: $cl-header-button;
                }
            }
        }
    }
}