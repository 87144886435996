div#activity {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 140px 0;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;

    div.activity-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 30px;
        
        a.tab {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            gap: 30px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

            padding: 20px 10px;
            width: 110px;
            min-width: 110px;
            height: 120px;
            border-radius: 16px;
            transition: 0.2s ease-in;

            &:hover {
                background-color: rgba(206, 175, 144, 0.2);
            }

            img {
                width: 50px;
                height: 50px;
            }
        }
    }

    @keyframes slide {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-100%);
        }
    }
}