.flex {
    display: flex;
    gap: 30px;
}

.flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-column {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.grid-center {
    display: grid;
    place-items: center;
}