$rooms-background: #DBCDBD;

div#rooms {
    text-align: center;
    padding: 140px 20px;
    background-color: rgba($rooms-background, 0.3);

    h2 {
        margin-bottom: 70px;
    }

    div.room-showcase-container {
        display: flex;
        justify-content: center;
        gap: 30px;

        a {
            width: 440px;
            height: 490px;
            border-radius: 16px;   
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            color: white;
            padding: 30px;
        }
    }
}