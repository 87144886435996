$cl-aside-background: #27555B;

aside {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: repeat(1, 1fr);
    background-color: var(--cl-white);
    z-index: 999;

    & > div {
        padding: 60px;
    }

    & > div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        div.image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url('../../../assets/pictures/Aside-Background.jpg');
            background-size: cover;
            filter: brightness(45%);
        }

        nav,
        button {
            z-index: 999;
        }

        a,
        button {
            color: white;
        }

        button.close {
            display: flex;
            align-items: center;
            gap: 6px;
            background-color: transparent;
            cursor: pointer;
            border: none;
            outline: none;

            span {
                font-size: 16px;
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        nav {
            display: flex;
            flex-direction: column;
            padding-top: 16px;

            button {
                min-width: 220px;
                text-align: left;
                background-color: transparent;
                font-size: 36px;
                font-weight: 600;
                transition: all 0.2s ease-in;
                padding-bottom: 16px;

                &:hover {
                    color: #947B57;
                }
            }
        }
    }

    & > div:last-child {
        display: flex;
        flex-direction: column;
        gap: 50px;
        background-color: $cl-aside-background;
        color: var(--cl-white);

        div.row {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1, h2 {
                font-size: 26px;
            }

            h1 {
                margin-bottom: 12px;
            }

            h2 {
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 12px;
            }

            img {
                margin-bottom: 20px;
                width: 190px;
            }
        }
    }
}