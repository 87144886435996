@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Murecho:wght@100..900&display=swap');

$font-cormorant: "Cormorant Garamond", serif;
$font-murecho: "Murecho", sans-serif;
$font-jost: "Jost", sans-serif;
$cl-site-background: #faf9f8;

:root {
  --cl-white: #faf9f8;
  --cl-text: #181b1f;
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

.font-jost {
  font-family: $font-jost;
}

.font-cormorant {
  font-family: $font-cormorant;
}

.font-murecho {
  font-family: $font-murecho;
}

body {
  background-color: $cl-site-background;
  font-family: $font-jost;
  color: var(--cl-text);;
}

a {
  text-decoration: none;
  color: var(--cl-text);
}

a,
button {
  cursor: pointer;
}

button {
  border: none;
  outline: none;
}

ul, ol {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  font-weight: 500;
}

.text-64 {
  font-size: 64px;
}

h2.title {
  font-family: $font-cormorant;
  font-size: 48px;
  font-weight: 600;
}

.subtitle {
  font-family: $font-cormorant;
  font-size: 32px;
}

section {
  // margin-top: 84px;
  // min-height: calc(100vh - 84px - 539px);
  min-height: calc(100vh - 84px - 539px);
}

span.number {
  font-family: $font-jost;
  font-weight: 300;
}

.logo-font {
  font-family: "Murecho", sans-serif;
}

p {
  a {
    color: #3d80c1;
  }
}