section#blog {
    div.blog-container {
        display: grid;
        grid-template-columns: 7fr 3fr;
        gap: 40px;
        padding: 80px 40px;

        div.post-container {
            display: flex;
            flex-direction: column;
            gap: 40px;

            div.post {
                height: 400px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
    
                & > a.image {
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                }

                & > div.text {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    padding: 20px 30px;

                    div.category-title {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 17px;
                        padding: 5px 0;
                        display: flex;
                    }

                    a {
                        transition: all 0.2s ease-in-out;

                        &.title {
                            font-size: 40px;
                            font-weight: 500;
                        }
    
                        &.read-more {
                            display: flex;
                            align-items: center;
                            width: max-content;
                            margin-top: 5px;
                            padding: 3px;
    
                            span.text {
                                margin-bottom: 2px;
                            }
                        }
    
                        &:hover {
                            // color: #998672;
                            color: #27555b;
                        }
                    }
                }
            }
        }
    }

    div.blog-navigation {
        display: flex;
        flex-direction: column;
        gap: 60px;

        h3 {
            font-size: 30px;
            margin-bottom: 20px;
        } 
        
        div ul li {
            font-size: 17px;
            font-weight: 400;
            padding: 10px 0 15px 0;
            display: flex;
            align-items: center;
            gap: 5px;
            height: 20px;
            margin-bottom: 16px;
            border-bottom: 1px solid #DDDDDD;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            
            &:last-child {
                border-bottom: none;
                margin: 0;
            }

            &:hover,
            &.selected {
                // color: #998672;
                color: #27555b;
            }
        }

        div.recent-post-container {
            display: grid;
            grid-template-columns: auto;
            gap: 20px;

            div.small-blog {
                display: grid;
                grid-template-columns: auto auto;
                gap: 15px;
                height: 90px;

                a.image {
                    width: 100%;
                    aspect-ratio: 1 / 1;
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                }

                div.text {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    a {
                        font-weight: 600;
                        font-size: 21px;

                        &:hover {
                            color: #27555b;
                        }
                    }

                    div.category-title {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 14px;
                        padding: 5px 0;
                        display: flex;
                    }
                }
            }
        }
    }

    .arrows {
        display: flex;
        align-items: center;

        svg:nth-child(2) {
            margin-left: -14px;
        }
    }
}