$cl-selector-button: #947b57;

div.language-selector {
    z-index: 100;
    display: flex;
    gap: 12px;

    button {
        padding: 3px 6px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 6px;
        min-height: 28px;
        font-size: 16px;
        background-color: rgba($cl-selector-button, 0.9);
        font-weight: 500;
    }
}
