section.home {
    div.banner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 60px;
        height: 85vh;
        color: var(--cl-white);
        background-image: url('../../assets/pictures/HeroSlides/Home.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        div.text {
            margin-top: 84px;

            div.container {
                display: flex;
                align-items: center;
                gap: 10px;

                div.hr-line {
                    width: 100%;
                    height: 2px;
                    background-color: white;
                }

                h1 {
                    padding: 0 22px;
                    font-size: 48px;
                    font-weight: 300;
                    letter-spacing: 18px;
                }
            }

            h1 {
                text-align: center;
                text-transform: uppercase;
                font-size: 72px;
                font-weight: 400;
                letter-spacing: 6px;
            }

            h1:last-child {
                font-size: 104px;
            }
        }

        
    }

    h2.title {
        font-family: "Cormorant Garamond", serif;
        font-size: 54px;
        font-weight: 700;
    }
    
    p.title-text {
        max-width: 900px;
        margin: 16px auto 70px auto;
    }
}