section.reservation {
    display: flex;
    gap: 24px;
    color: hsl(245, 24%, 18%);
    padding: 60px;

    div.single-form {
        width: 100%;
    }

    div.summary {
        min-width: 400px;
        padding: 12px 16px;
        background-color: white;
        border: 1px solid #dadadd;
        border-radius: 6px;

        div.group {
            padding: 16px;
            border-bottom: 1px solid #dadadd;

            &:last-of-type p {
                font-size: 16px;
            }
        }

        p {
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: 14px;

            &.bigger {
                font-size: 24px;
                margin-bottom: 12px;
            }
        }

        div.stars {
            display: flex;
            align-items: center;
            gap: 2px;
        }

        img {
            width: 80px;
            height: 80px;
            border-radius: 6px;
        }

        div.row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0px;
            }

            p {
                font-weight: 500;
            }
        }

        p.price {
            color: #068484;
        }

        button {
            margin: 16px 0 4px 0;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            cursor: pointer;
            color: #fff;
            background-color: #068484;
            border-radius: 60px;
            width: 100%;
            padding: 11px;
            border: none;
            outline: none;
        }
    }
}