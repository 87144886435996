div#showcase {
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 140px 0 70px 0;

    div.images {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 100px;

        img {
            border-radius: 16px;
            width: 400px;
            height: 400px;
            transition: all 0.4s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }

        img.bigger {
            height: 520px;
        }
    }

    h1.background-text {
        z-index: -1;
        position: absolute;
        left: 50%;
        bottom: 170px;
        transform: translate(-50%, -50%);
        font-size: 175px;
        color: rgba(219,205,189, 0.4);
        white-space: nowrap;
    }

    div.second-text {
        margin-top: 70px;

        h1 {
            font-size: 120px;
            font-weight: 600;
        }
    }
}