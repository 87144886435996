div#instagram {
    padding: 120px 0;
    text-align: center;

    p.profile {
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 30px;
    }

    p.title-text {
        margin-bottom: 90px;
        max-width: 740px;
        margin: 20px auto 90px auto;
    }

    div.post-container { 
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        padding: 0 10px;

        a {
            display: grid;
            place-items: center;

            img {
                max-width: 100%;
                border-radius: 16px;
            }
        }
    }
}