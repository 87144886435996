div.hero-section {
    position: relative;
    display: grid;
    place-items: center;
    height: 65vh;
    color: var(--cl-white);
    background-size: cover;

    div.image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        filter: brightness(45%);
    }

    div.text {
        z-index: 10;

        h1 {
            font-family: var(--font-cormorant);
            text-align: center;
            font-size: 92px;
            z-index: 10;

            &:last-of-type {
                margin-bottom: 20px;
            }
        }
    }
}

a.scroll-down-container {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: bounce 2s infinite;
    padding: 6px;

    svg:not(:first-child) {
        margin-top: -30px;
    }
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(15px);
    }
}