:root {
    --cl-footer-background: #27555B;
}

footer {
    background-color: var(--cl-footer-background);
    color: var(--cl-white);
}

footer a {
    color: var(--cl-white);
}

/* Top */

footer div.top {
    display: flex;
    gap: 80px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 100px 60px;
}

footer div.top > div {
    flex: 1;
}

footer div.top h4 {
    font-family: var(--font-cormorant);
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 40px;
}

footer div.top div.links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

footer div.top div.links a {
    display: flex;
}

footer div.top a {
    display: flex;
    align-items: center;
    gap: 10px;
}

footer div.top a svg {
    width: 18px;
    height: 18px;
}

footer div.top a,
footer div.top p {
    color: rgba(255, 255, 255, 0.6);
    line-height: 1.7;
    font-size: 15px;
}

/* Bottom */

footer div.bottom {
    display: flex;
    align-items: center;
    padding: 0 60px;
    height: 125px;
}

footer div.bottom > div {
    width: 100%;
}

footer div.bottom a.logo {
    display: grid;
    place-items: center;
}

footer div.bottom a.logo img {
    height: 42px;
}

footer div.bottom div.links {
    gap: 5px;
}

footer div.bottom div.links a {
    display: grid;
    place-items: center;    
    padding: 10px;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #947b57;
    }
}

@media (max-width: 800px) {
    footer div.top {
        flex-direction: column;
    }
}