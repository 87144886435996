section#contact {
    div.emails-container,
    div.map-form-container {
        padding: 0 80px;
    }

    div.emails-container {
        padding-top: 100px;
        padding-bottom: 40px;
        display: flex;
        gap: 16px;
    }

    div.map-form-container {
        padding-bottom: 100px;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 100px;
    }
}

form {
    &.contact {
        min-width: 450px;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

div.row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

label {
    &.search {
        display: flex;
        gap: 5px;

        button {
            display: grid;
            place-items: center;
            min-width: 36.5px;
            min-height: 36.5px;
            border-radius: 4px;
            color: white;
            background-color: #27555b;
        }
    }

    span {
        word-break: break-word;
        font-weight: 500;
        font-size: 15px;

        &.star {
            color: #fa5252;
        }

        &:first-child {
            margin-left: 2px;
        }
    }
}

input,
textarea {
    display: block;
    width: calc(100% - 24px - 2px);
    transition: border-color 0.1s ease;
    color: #000;
    border: 1px solid #ced4da;
    background-color: white;
    font-family: "Jost", sans-serif;
    min-height: 36px;
    // line-height: 32px;
    font-size: 14px;
    border-radius: 4px;
    padding: 0 12px;
    cursor: text;
    outline: none;

    &:focus {
        border-color: #27555b;
    }
}

input {
    height: 36px;
}

textarea {
    padding: 12px;
    height: 180px;
    resize: none;
}

.grid-center {
    display: grid;
    place-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 6px;
}



button.btn {
    padding: 10px 20px;
    display: grid;
    place-items: center;
    position: relative;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #FFF;
    background-color: #947b57;
    width: 100%;
    height: 42px;
    transition: 0.25s ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    &.loader span {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 24px 0 #fff, -24px 0 #fff;
        animation: flash 0.5s ease-out infinite alternate;
    }

    &:hover {
        transform: scale(1.02);
    }

    @keyframes flash {
        0% {
            background-color: #fff2;
            box-shadow: 24px 0 #fff2, -24px 0 #fff;
        }
        50% {
            background-color: #fff;
            box-shadow: 24px 0 #fff2, -24px 0 #fff2;
        }
        100% {
            background-color: #fff2;
            box-shadow: 24px 0 #fff, -24px 0 #fff2;
        }
    }
}

div.alert-container {
    display: none;
    align-items: center;
    gap: 6px;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid;

    &.success {
        display: flex;
        color: #12B886;
        background-color: rgba(18, 184, 134, .1);
    }

    &.error {
        display: flex;
        color: #FA5252;
        background-color: rgba(250, 82, 82, .1);
    }
}